<template>
    <div class="Login">
        <div class="head">
            <img class="head-logo" :src="require('@/assets/img/logo.png')" />
        </div>

        <p class="title">欢迎登录</p>

        <van-form @submit="Login" class="user">
            <van-cell-group inset class="user-form">
                <p class="user-name">账号</p>
                <div class="flex-row">
                    <span class="iconfont icon-a-iconpay_huaban1fuben48"></span>
                    <van-field v-model="username" name="username" placeholder="请输入账号" />
                </div>

                <p class="user-name">密码</p>
                <div class="flex-row">
                    <span class="iconfont icon-a-iconpay_huaban1fuben44"></span>
                    <van-field v-model="password" type="password" name="password" placeholder="请输入密码" />
                </div>

                <p class="user-name">验证码</p>
                <div class="flex-row">
                    <span class="iconfont icon-a-iconpay_huaban1fuben12"></span>
                    <van-field v-model="imgcode" name="imgcode" placeholder="请输入验证码" />

                    <img class="img-code" :src="imgSrc" @click="getImgCode" />
                </div>
            </van-cell-group>

            <div class="user-btn">
                <van-button block type="primary" native-type="submit">确定</van-button>
            </div>
        </van-form>
        <div class="register">
            <span @click="isShow = true">
                没有账户？
                <span style="color: #6d88fb;">立即注册</span>
            </span>
        </div>
        <p class="kefu">
            <span @click="forgetPw">忘记密码？</span>
            <span @click="router.push('/Service')">在线客服</span>
        </p>

        <van-action-sheet v-model:show="isShow" title="用户须知">
            <div class="content">
                <div class="content-text">
                    <p>
                        k宝钱包是基于C2C原理开发的应用平台，交易是会员与 会员之间的大厅互买模式。敬请大家一定要注意，并非 充值模式。流程示范：注册→购买K币→到商户平台充 值→提款下分到K宝钱包→到我要卖币挂单等待买家购买 →买家付款→卖家确认→完成交易！在交易过程当中， 还有一些注意事项，请您务必阅读。
                    </p>

                    <p>
                        第一：为了保证会员之间的交易安全，平台采取的是实 名制用户认证，并终身无法修改认证信息！会员必须遵 守规定，严格启用本人身份信息，银行账户信息,等；买家付款时必须使用本人身份信息的银行卡或者其他支付工具来购买，如果使用他人银行账户代为付款，卖家有权可以不予确认收款。由于本人录入信息不符而造成的一切损失，由会员自行承担，平台不担负任何责任；
                    </p>

                    <p>
                        第二：为了保证卖家的信息安全，请买家务必在有需求的时候再进行订单确认，如若发生恶意刷单，锁定卖家额度而不付款的用户，平台将会自动冻结账户；
                    </p>

                    <p>
                        第三：在购买以及出售K币的交易时间，平台规定是必须在5分钟在内完成，会员在购买或者出售的时候，一定要非常关注交易是否正在进行当中，如有异议请及时联系平台客服，申请人工介入！由于买卖双方长时间不确定的交易，系统将自动判定为成交或者取消，由于系统判定是无法做到百分百准确，平台不承担任何责任。再次重申，请双方在交易的过程当中要时刻注意订单变化！
                    </p>

                    <p>
                        第四：『买家』在购买K币的时候，此订单一旦付款成功，请立即点击『我已转账』确认，确认以后5分钟内如果还没有收到币，此订单便为争议订单，请立即联系在线客服，申请人工介入！
                    </p>

                    <p>
                        第五：会员在出售K币的时候，此订单一旦挂单成功，如有『买家』下单购买并点击了『我已转账』确认，请您马上查询是否收到汇款，如没有收到『买家』的转账，请立即点击『暂停打币』功能，并联系在线客服，申请人工介入！
                    </p>

                    <p>第六：买卖双方在卖币买币过程当中，如果恶意暂打币，故意为难买家，平台将会冻结账户。</p>
                </div>

                <div class="btn">
                    <van-button class="quxiao" type="primary" round size="normal" @click="isShow = false">取 消</van-button>
                    <van-button type="primary" round size="normal" @click="toRegister">确 定</van-button>
                </div>
            </div>
        </van-action-sheet>
    </div>
</template>

<script>
import { reactive, toRefs, nextTick, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { GetImgCode, userLogin } from '@/api'
import md5 from 'js-md5'
import { Toast } from 'vant'

export default {
    setup() {
        const state = reactive({
            username: '',
            password: '',
            username: 'pay0001',
            password: 'pay0001',
            imgcode: '',
            imgSrc: '',
            checked: false,
            isShow: false
        })

        const router = useRouter()
        const store = useStore()

        // 获取图形验证码
        const getImgCode = () => {
            GetImgCode().then(res => (state.imgSrc = res.data.image))
        }

        store.dispatch('getFileConfig')

        // 默认先获取一次
        getImgCode()

        // 登录
        const Login = params => {
            userLogin({
                username: params.username,
                password: md5(params.password),
                imgcode: params.imgcode
            }).then(res => {
                if (res.status) {
                    Toast.success('登录成功！')
                } else {
                    Toast.fail(res.message)
                    getImgCode()
                }
            })
        }

        const toRegister = () => {
            state.isShow = false
            router.push('/Register')
        }

        const forgetPw = () => {
            router.push('/GetPw')
        }

        nextTick(() => {
            document.removeEventListener('touchmove', window.touchmoveListener, { passive: false })
        })
        onUnmounted(() => {
            document.addEventListener('touchmove', window.touchmoveListener, { passive: false })
        })

        return {
            ...toRefs(state),
            router,
            Login,
            getImgCode,
            toRegister,
            forgetPw
        }
    }
}
</script>

<style lang="scss" scoped>
// 用户须知弹窗按钮
.btn ::v-deep .van-button {
    width: w(130);
    height: h(45);
    border: none;
}

.Login {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    text-align: center;
    color: #000000;
    padding: 0 h(30);

    .head {
        margin-top: h(75);

        .head-logo {
            margin-right: w(7);
            vertical-align: -15px;
        }
    }

    .title {
        margin-top: h(16);
        font-size: 20px;
    }

    .user {
        margin-top: h(19);
        font-size: 10px;

        .flex-row {
            position: relative;
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            min-height: 40px;
            height: h(40);
            line-height: h(50);

            .img-code {
                max-height: 100%;
                height: h(50);
                position: absolute;
                right: 0;
            }
        }

        .user-name {
            margin-top: h(21);
            text-align: left;
            color: #333333;
            font-size: 14px;
            line-height: 18px;
        }

        .user-btn {
            margin-top: h(58);
            font-size: 18px;
        }
    }

    .register {
        margin-top: h(40);
    }

    .kefu {
        margin-top: h(30);
        text-align: right;
        color: #6d88fb;
        font-size: 14px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
    }

    .content {
        padding: 16px 16px h(30);
        text-align: left;

        .content-text {
            height: h(350);
            overflow-x: hidden;
            overflow-y: scroll;

            p {
                margin-bottom: h(15);
                font-size: 12px;
                line-height: 16px;
                color: #000000;
            }
        }

        .btn {
            margin-top: h(30);
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-around;

            .quxiao {
                background: #f2f2f2;
                color: #828282;
            }
        }
    }
}
</style>
